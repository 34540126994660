export const Level2Map = {
    image: "level2.png"
};

export const Level2CharacterOptions = {
    x: 250,
    y: 150
};

export const Level2MapObjects = {
    
};